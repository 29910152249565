
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import EditCampana from "./edit-campana.vue";
import FindCampana from "./find-campana.vue";
import PeopleFind from "../../../components/People/people-find.vue";
import Cliente from "../../../store/entities/OZONE/cliente";
import Marca from "../../../store/entities/OZONE/marca";
import History from "../audit/history.vue";
import { notification } from "ant-design-vue";

class PageCampanaRequest extends PageRequest {
  keyword: string;
  incluirDocumentales: boolean;
  fechaInicio: Date;
  fechaFin: Date;
}

@Component({
  components: {
    EditCampana,
    FindCampana,
    PeopleFind,
    History,
  },
})
export default class Campanas extends AbpBase {
  //filters
  pagerequest: PageCampanaRequest = new PageCampanaRequest();
  creationTime: Date[] = [];
  authorsHeaderBtns: String = "all";
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  findModalShow: boolean = false;
  clienteNombre: string = "";
  historyModalShow: boolean = false;
  entityId: number = 0;
  currentSearch: { key: string; value: string; label: string }[] = [];

  cliente: Cliente = null;
  marca: Marca = null;

  searchDefinition: {}[] = [
    {
      fieldName: "Busqueda",
      fieldType: "text",
      fieldBind: "keyword",
      value: "",
    },
    {
      fieldName: "Cliente",
      fieldType: "custom",
      display: "nombre",
      custom: "select-cliente",
      fieldBind: "cliente",
      value: this.cliente,
    },
    {
      fieldName: "Marca",
      fieldType: "custom",
      display: "nombre",
      custom: "select-marca",
      fieldBind: "marca",
      value: this.marca,
    },
    {
      fieldName: "Fecha Inicio",
      fieldType: "date",
      fieldBind: "fechaInicio",
      value: "",
    },
    {
      fieldName: "Fecha Fin",
      fieldType: "date",
      fieldBind: "fechaFin",
      value: "",
    },
    {
      fieldName: "Incluir Documentales",
      fieldType: "checkbox",
      fieldBind: "incluirDocumentales",
      value: false,
    },
  ];

  getSearch = async (model) => {
    this.currentSearch.length = 0;

    model.forEach((element) => {
      console.log(element);
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
        this.currentSearch.push({
          label: element.fieldName,
          key: element.fieldBind,
          value:
            element.fieldType === "custom"
              ? element.value[element.display]
              : element.value,
        });
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });
    await this.getpage();
    this.findModalShow = false;
  };
  $store: any;
  $router: any;

  
  async deleteFilter(ixFilter) {
    var filter = this.currentSearch[ixFilter];
    delete this.pagerequest[filter.key];
    (
      this.$refs.peoplefind as Vue & { resetField: (key: string) => void }
    ).resetField(filter.key);

    await this.getpage();
  }
  async reset() {
    (this.$refs.peoplefind as Vue & { reset: () => void }).reset();
    this.currentSearch.length = 0;
  }

  pagination: any = {};

  get list() {
    return this.$store.state.campana.list;
  }
  get loading() {
    return this.$store.state.campana.loading;
  }

  async create() {
    this.createModalShow = true;
    // this.pagerequest = new PageCampanaRequest()
    // await this.$store.dispatch({
    //   type: 'marca/getAll'
    // })
    this.$router.push({ name: "createCampana" });
  }

  editRow(item, index, button) {
    this.$store.commit("campana/edit", item);
    this.edit();
    this.$router.push({ name: "editCampana", params: { id: item.id } });
  }
  showHistory(item, index, button) {
    this.entityId = item.id;
    this.historyModalShow = true;
  }
  async cloneRow(item, index, button) {
    await this.$store.dispatch({
      type: "campana/clone",
      data: item,
    });
    //this.edit();
    await this.getpage();
  }

  async realizarSorteo(item, index, button) {
   const fileData = await this.$store.dispatch({
      type: "campana/realizarSorteo",
      data: item,
    });
    
   if(this.downloadFile(fileData, 'sorteo.zip')){
    // notification.success({
    //     message: 'Ok',
    //     description: 'Sorteo generado!',
    //   });
    await this.getpage();
   }
   
  }

  async recuperarSorteo(item, index, button) {
    const fileData = await this.$store.dispatch({
      type: "campana/recuperarSorteo",
      data: item,
    });
    if(this.downloadFile(fileData, 'sorteo.zip')){
      // notification.success({
      //   message: 'Ok',
      //   description: 'Sorteo recuperado!',
      // });
    }
    
    await this.getpage();
  }

  downloadFile(data, filename) {
    
    if(data === undefined){
      return false;
    }
    try {
        const byteCharacters = atob(data.result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/x-rar-compressed' });

        // Crear URL de descarga
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        console.log("Archivo descargado correctamente.");
        return true;
    } catch (error) {
        console.error("Error al decodificar y descargar el archivo:", error);
        return false;
    }
  }

  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar esta campaña?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: "campana/delete",
            data: item,
          });
          await this.getpage();
        }
      });
  }
  addParticipacion(item, index, button) {
    
    this.$router.push({
      name: "editparticipacion",
      params: { campanaId: item.id },
    });
  }
  addParticipacionFichero(item, index, button) {
    
    this.$router.push({
      name: "charlie-plantillas",//"charlie-mapeo",
      params: { campanaId: item.id },
    });
  }

  edit() {
    this.editModalShow = true;
  }

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  pageChange(page: number) {
    this.$store.commit("campana/setCurrentPage", page);
    this.getpage();
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit("campana/setPageSize", pagesize);
    this.getpage();
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
    //filters

    // if (this.creationTime.length > 0) {
    //   this.pagerequest.fechaInicio = this.creationTime[0]
    // }
    // if (this.creationTime.length > 1) {
    //   this.pagerequest.fechaFin = this.creationTime[1]
    // }
    console.log(this.pagerequest);
    await this.$store.dispatch({
      type: "campana/getAll",
      data: this.pagerequest,
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.campana.totalCount;
    pagination.pageSize = this.$store.state.campana.pageSize;
    pagination.current = this.$store.state.campana.currentPage;
    pagination.showSizeChanger = true;
    pagination.pageSizeOptions = ['10', '50', '100']
    this.pagination = pagination;
  }

  get pageSize() {
    return this.$store.state.campana.pageSize;
  }

  get totalCount() {
    return this.$store.state.campana.totalCount;
  }

  get currentPage() {
    return this.$store.state.campana.currentPage;
  }

  set currentPage(page) {
    this.$store.commit("campana/setCurrentPage", page);
    this.getpage();
  }
  stateColorScheme(state: number) {
    switch (state) {
      case 1:
        return "text-success";
      case 2:
        return "text-dark";
      case 3:
        return "text-secondary";
      case 4:
        return "text-danger";
      case 5:
        return "text-warning";
      case 6:
        return "text-success";
    }
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("campana/setPageSize", pagination.pageSize);
    this.$store.commit("campana/setCurrentPage", pagination.current);
    this.getpage();

    await this.getpage();
  };
  async getCliente(clienteId) {
    var cliente = "";
    var result = this.$store.dispatch({
      type: "cliente/get",
      id: clienteId,
    });
    return this.$store.state.cliente.editCliente.cliente.nombre;
  }

  canAddParticipaciones(state: number) {
    switch (state) {
      case 1:
        return true;
      case 2:
        return false;
      case 3:
        return false;
      case 4:
        return false;
      case 5:
        return true;
      case 6:
        return false;
    }
  }

  columns = [
    {
      title: this.L("ID"),
      dataIndex: "id",
    },
    {
      title: this.L("Ekon"),
      dataIndex: "axapta",
    },
    {
      title: this.L("Nombre"),
      scopedSlots: { customRender: "func" },
    },
    {
      title: this.L("Tipo"),
      dataIndex: "campanaTipoNombre",
      scopedSlots: { customRender: "status" },
    },
    {
      title: this.L("Cliente"),
      dataIndex: "clienteNombre",
    },
    {
      title: this.L("Fecha Inicio"),
      dataIndex: "fechaInicio",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.L("Fecha Fin"),
      dataIndex: "fechaFin",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.L("Creada"),
      dataIndex: "creationTime",
      scopedSlots: { customRender: "date" },
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ];

  async created() {
    this.getpage();
    // await this.$store.dispatch({
    //   type: 'campo/getAll',
    //   data: this.pagerequest
    // })
  }
}
